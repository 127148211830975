.history-slider {
    margin-top: 100px;
    margin-bottom: 60px;
    position: relative;
    margin-right: auto;

    &__image {
        max-width: 1328px;
        width: 100%;
        max-height: 548px; }

    &__wrap {
        display: grid;
        grid-template-columns: 0.2fr 0.5fr 1fr;
        align-items: flex-start;
        padding-top: 40px; }

    .history-slider__nav {
        margin-left: 60px;
        margin-left: 0; }

    .history-slider__btn {
        padding: 20px;
        background: #F2DF75;
        border-radius: 3px;
        border: none; }

    .slider-icon {
        font-size: 18px; }

    .swiper-button-disabled {
        opacity: 0.5; }

    .swiper-slide-next {
        transition: 0.3s ease; }

    .swiper-slide-active {
        transition: 0.3s ease; }

    .swiper-slide-prev {
        transition: 0.3s ease; }

    .swiper-slide {
        top: 1px; } }

.line-info {
    position: relative;

    &__section {
        position: absolute;
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        top: -122px;
        left: 267px;
        width: 100%; }

    &__title {
        margin-right: 20px; }

    &__text {
        padding-right: 120px; } }

@media  (max-width: 1300px) {
    .line-info__text-block {
        max-width: none; }

    .line-info__text {
        max-width: none; } }

@media  (max-width: 1280px) {
    .history-slider .history-slider__nav {
        margin-left: 128px; }

    .swiper-slide {
        margin-right: 50px; } }

@media  (max-width: 1024px) {
    object-fit: cover; }

@media  (max-width: 1023px) {
    .history-slider__wrap {
        grid-template-columns: 0.2fr 1fr;
        gap: 20px; }

    .line-info__text-block {
        grid-column: 1/3;
        margin-left: 16px;
        margin-right: 50px; }

    .line-info__text-block {
        max-width: none;
        margin-left: 126px; }

    .line-info__text {
        max-width: none;
        margin-left: 0; } }

@media  (max-width: 767px) {
    .history-slider__wrap {
        padding-right: 0px; }

    .line-info__title {
        grid-column: 1/3;
        margin-left: 16px; }

    .history-slider .history-slider__nav {
        margin-left: 16px; }

    .line-info__text-block {
        margin-left: 16px; } }

@media  (min-width: 640px) {
    .history-slider {
        .swiper-container {
            position: relative;
            left: calc( (130vw - 200px)/(1980 - 640) * (200 - 0) + 0px); } } }

@media  (max-width: 639px) {
    .history-slider {
        .swiper-container {
            position: relative;
            left: 0px; } } }

@media  (max-width: 600px) {
    .line-info__text-block {
        margin-left: 16px;
        padding-right: 0px; }

    .line-info__text {
        margin-left: 0px;
        padding-right: 0px; } }

@media  (max-width: 320px) {

    .history-slider {
        .swiper-container {
            position: relative;
            left: -20px; } } }
