.page {
	flex: 1; }

.history-slider {
	display: flex;
	flex-direction: row;

	&__nav {
		width: 200px; }

	&__content {
		width: calc(100% - 200px); } }
