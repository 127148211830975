.slider-tab {

    &__schemes {
        margin-left: 150px; }

    &__btn {
        padding: 20px;
        background: #F2DF75;
        border-radius: 3px;
        border: none;

        &:hover {
            background: #FFEE93; }

        &:disabled {
            opacity: 0.5; } }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 510px;
        width: 100%;
        margin-bottom: 40px;
        margin-top: 49px; }

    &__title-mini {
        padding: 15px 0px; }

    &__block {
        max-width: 328px;
        width: 100%;

        .slide-img {
            width: 100%;
            height: 100%;
            object-fit: contain; } } }

.slider-tab__container {

    .swiper-container {
        overflow: hidden; } }

.swiper-button-disabled {
    opacity: 0.5; }

.slider-icon {
    font-size: 18px; }

@media  (max-width: 1800px) {
    .tabs-header {
        margin-left: 0px;
        margin-right: 0px; } }

@media  (max-width: 1278px) {
    .slider-tab {

        &__schemes {
            margin-left: 80px; } } }

@media  (max-width: 1024px) {
    .slider-tab {

        &__schemes {
            margin-left: 55px; } } }

// @media  (max-width: 550px)
// 	.swiper-slide
// 		width: 100% !important

@media  (max-width: 320px) {
    .slider-tab {

        &__schemes {
            margin-left: 16px; } } }
