.not-found {

	&__section {
		background-color: #F2DF75;
		margin: 0 20px; }

	&__block {
		margin-left: 500px; }

	&__image {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 1747px;
		width: 100%;
		height: 710px;
		background-image: url(../img/404.svg);
		background-repeat: no-repeat; }

	&__info {
		position: absolute;
		left: 650px;
		top: 200px; }

	&__main-title {
		margin-bottom: 15px; }

	&__title {
		margin-bottom: 30px; }

	&__text {
		max-width: 362px;
		width: 100%; }

	&__link {
		text-decoration: underline; } }

@media  (max-width: 1023px) {
	.not-found__block {
		margin-left: 0;
		margin-top: 200px; } }

@media  (max-width: 767px) {

	.not-found__image {
		background-image: none;
		height: 372px; }

	.not-found__block {
		margin-top: 0px;
		text-align: center; } }
