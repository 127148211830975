
.menu {

	&__item {
		position: relative;
		display: flex;
		align-items: center;

		&--center {
			justify-content: center; } }

	&__link {
		display: flex;
		align-items: center;
		margin-bottom: 25px;

		&--pointer {
			cursor: pointer; }

		&--deg {
			transform: rotate(180deg); }

		&-icon {
			position: relative;
			font-size: 9px;
			margin-left: 5px;
			transform: rotate(0);
			transition: transform .3s ease;

			&--pointer {
				margin-right: 29px; } } }

	&__dropdown {
		position: absolute;
		display: none;
		width: 300px;
		z-index: 2;
		left: 0;
		top: 100px;
		list-style: none;
		padding-left: 0;
		transition: bottom .3s ease, opacity .3s ease, visibility .3s ease;
		background-color: #fff;
		box-shadow: 0 0 0 3px rgba(#000, .2); } }

.dropdown {

	&__link {
		display: flex;
		padding: 10px;

		&:hover {
			color: #B33525; } } }

.menu-container {
	// max-width: 2078px
	width: 100%;
	// height: 100vh
	display: flex;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	transition: opacity .5s ease, visibility .5s ease, transform .5s ease;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;

	&__burger {
		margin-top: 95px;
		justify-content: flex-start; }

	&--open {
		opacity: 1;
		z-index: 4;
		visibility: visible;
		transform: translateX(0);
		// overflow: auto
		background: #F2DF75; }

	&__logo {
		position: relative;
		width: 50%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFFFFF; }

	&__info {
		width: 50%;
		background: #F2DF75;
		height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 36px 0px 0px 141px; }

	&__link {
		margin-left: 47px; }

	&__logo-inside {
		position: absolute; } }

.menu__link--deg {
	transform: rotate(180deg); }

.menu-container__logo-outside {
	animation: 1s linear 0s normal none infinite running rot;
	-webkit-animation: 33s linear 0s normal none infinite running rot; }

@keyframes rot {
	0% {
		transform: rotate(0deg); }

	100% {
		transform: rotate(360deg); } }

@-webkit-keyframes rot {
	0% {}
	transform: rotate(0deg);

	100% {}
	transform: rotate(360deg); }

@media  (max-width: 1600px) {
	.menu-container__info {
		padding: 36px 0px 0px 10px; } }

@media  (max-width: 660px) {
	.menu-container__info {
		width: 100%; }

	.menu-container__logo {
		display: none; }

	.menu-container__burger {
		margin-top: 35px; } }
