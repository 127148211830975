.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

#SVG_container {
  position: absolute;
  height: 0;
  width: 0;
}

#SVG_container svg {
  position: absolute;
  height: 0;
  width: 0;
}

.icon-1 {
  font-size: (18/10)*1rem;
  width: (18/18)*1em;
}
.icon-10 {
  font-size: (18/10)*1rem;
  width: (15/18)*1em;
}
.icon-11 {
  font-size: (30/10)*1rem;
  width: (30/30)*1em;
}
.icon-12 {
  font-size: (30/10)*1rem;
  width: (30/30)*1em;
}
.icon-13 {
  font-size: (30/10)*1rem;
  width: (30/30)*1em;
}
.icon-14 {
  font-size: (30/10)*1rem;
  width: (30/30)*1em;
}
.icon-15 {
  font-size: (30/10)*1rem;
  width: (30/30)*1em;
}
.icon-16 {
  font-size: (30/10)*1rem;
  width: (30/30)*1em;
}
.icon-17 {
  font-size: (44/10)*1rem;
  width: (43/44)*1em;
}
.icon-18 {
  font-size: (44/10)*1rem;
  width: (44/44)*1em;
}
.icon-19 {
  font-size: (44/10)*1rem;
  width: (44/44)*1em;
}
.icon-2 {
  font-size: (4/10)*1rem;
  width: (16/4)*1em;
}
.icon-20 {
  font-size: (49/10)*1rem;
  width: (49/49)*1em;
}
.icon-3 {
  font-size: (16/10)*1rem;
  width: (16/16)*1em;
}
.icon-4 {
  font-size: (9/10)*1rem;
  width: (13/9)*1em;
}
.icon-5 {
  font-size: (14/10)*1rem;
  width: (18/14)*1em;
}
.icon-6 {
  font-size: (14/10)*1rem;
  width: (18/14)*1em;
}
.icon-7 {
  font-size: (20/10)*1rem;
  width: (18/20)*1em;
}
.icon-8 {
  font-size: (15/10)*1rem;
  width: (15/15)*1em;
}
.icon-9 {
  font-size: (18/10)*1rem;
  width: (15/18)*1em;
}
.icon-logo {
  font-size: (48/10)*1rem;
  width: (209/48)*1em;
}
.icon-wb {
  font-size: (19/10)*1rem;
  width: (98/19)*1em;
}
