.main-img {

	&__image {
		max-width: 1784px;
		width: 100%;
		height: 552px;
		object-fit: cover;
		border-radius: 5px;
		padding: 0 20px 0 20px; }

	.bread-crumbs {
		margin-left: 0; } }

.wide-video {
	max-width: 1784px;
	width: 100%;
	height: 552px;
	margin: 0 auto;
	object-fit: cover; }

.beginning-work {
	margin: 84px 0 100px;

	&__section {
		display: flex;
		justify-content: space-evenly; }

	&__text {
		max-width: 594px;
		margin-top: 12px; } }

.container_5 {
	max-width: 1604px;
	width: 100%;
	margin: 0 auto; }

.actual-news {

	&__main-img {
		width: 100%; }

	&__column {
		max-width: 636px;
		width: 100%;
		margin-bottom: 40px; }


	&__img-right {
		margin-bottom: 40px;
		max-width: 512px;
		width: 100%;

		img {
			width: 100%; } }

	&__title {
		margin-bottom: 70px;
		padding-left: 20px; }

	&__section {
		display: flex;
		justify-content: space-between;
		padding: 0 20px 0 20px; }

	&__button-show {
		max-width: 1608px;
		width: 100%;
		margin-top: 15px;
		background: #EDEDED;
		padding: 16.5px 0;
		border: none;

		&--hidden {
			display: none; } }

	&__news-block {

		&--border {
			border-bottom: 2px solid #000; } } }

.buttons-show {
	max-width: 1644px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px 0 20px; }

.news-block {
	margin: 0 20px 40px;

	// &--border
	// 	border-bottom: 2px solid #000

	//&__title
	//	margin: 0px 0 34px

	//--top
	//margin: 0 0 34px

	&__text {
		max-width: 456px;
		margin-bottom: 32px; }

	&__year {
		display: flex;
		margin-bottom: 32px; }

	&__img {
		margin: 0 25px 5px 0; }

	&__hr {
		size: 2px;
		color: #000000;
		margin-top: 23px; }

	&__btn {
		margin-bottom: 25px;
		display: block;
		max-width: 150px; } }

.actual-openNews {
	transition: ease .5s;

	&--hidden {
		display: none; } }

.slider-achievements {

	&__block {
		width: 246px;
		margin-bottom: 32px; }

	&__text {
		text-align: center; }

	&__img {
		margin-bottom: 14px; }

	.swiper-container {
		overflow: hidden; }

	.swiper-slide {
		width: 246px; } }

.dividing-strip {
	max-width: 1732px;
	width: 100%;
	height: 1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	margin: 0 auto;
	margin-bottom: 100px; }

@media  (max-width: 1500px) {

	//.actual-news__column-adapt
	//	display: contents

	//.actual-news__column
	//	max-width: 636px
	//	width: 100%

	.actual-news {

		&__column {
			max-width: 500px; } }

	.news-block__text {
		max-width: 551px; }

	.swiper-slide {
		margin-right: 0; }

	.slider-achievements__section {
		margin-left: 70px; } }

@media  (max-width: 1279px) {
	.actual-news__column-adapt {
		display: contents; }

	.actual-news__section {
		display: grid;
		grid-template-columns: 1fr 1fr; }

	.slider-achievements__img {
		width: 204px; }

	.slider-achievements__block {
		width: 204px; }

	.slider-years__section-year {
		width: 640px; }

	.actual-news__main-img {
		width: 100%;
		height: 411px;
		object-fit: cover; }

	.actual-news__news-block--adapt {
		display: none; } }

@media  (max-width: 1023px) {
	.actual-news__column-adapt {
		display: flex; }

	.slider-achievements__section {
		margin-left: 20px; }

	.actual-news__main-img {
		width: 270px;
		height: 276px;
		object-fit: cover; }

	.actual-news__section {
		display: grid;
		grid-template-columns: 0.7fr 1fr; }

	.actual-news__left-block {
		display: flex;
		grid-column: 1/3;
		margin-bottom: 20px; }

	.actual-news__column-adapt {
		grid-column: 1/3; }

	.actual-news__column {
		grid-column: 1/3;
		max-width: 900px;

		&--adapt {
			max-width: 274px; } }

	.actual-news__news-block {
		grid-column: 1/3; }

	.actual-news__news-block--border {
		border-top: 2px solid #000;
		border-bottom: none; }

	.news-block__title {
		margin-top: 0; }

	.actual-news__news-block--border {
		padding-top: 40px; }

	.beginning-work__section {
		display: block;
		padding: 0 20px; }

	.actual-news__img-right img {
		width: 100%;
		height: 100%;
		object-fit: cover; }

	.actual-news__img-right {
		max-width: 270px;
		height: 270px; } }

@media  (max-width: 767px) {
	.actual-news__left-block {
		border-bottom: 2px solid #000;
 }		//display: none
	.actual-news__img-right {
		display: none; }

	.actual-news__news-block--adapt {
		display: block;
		padding-top: 40px; }

	.actual-news__news-block--border {
		border-top: none;
		border-bottom: 2px solid #000; }

	.actual-news__column--adapt {
		display: none; }

	.news-block {
		margin: 0; }

	.actual-news__title {
		margin: 0 0 40px 0; }

	.wide-video {
		height: 250px; } }
