.slider-cards {
    margin-top: 100px;
    padding: 0 20px 0 20px;

    &__body {
        background: #F8F8F8;
        border-radius: 5px;
        display: flex;
        justify-content: space-between; }

    &__navigation {
        padding: 155px 0 0 66px; }

    &__buttons {
        position: relative;
        top: 40px; }

    &__container {
        max-width: 1350px;
        padding: 120px 100px;
        widt: 100%;
        overflow: hidden; }

    &__slide {
        max-width: 218px;
        width: 100%;
        border-radius: 5px; }

    &__img {
        display: block;
        height: 30px;
        margin: 20% auto 0;
        mix-blend-mode: darken;

        &--tal {
            width: 28%;
            mix-blend-mode: darken; }

        &--rus {
            width: 53%;
            mix-blend-mode: darken; }

        &--magn {
            width: 60%;
            mix-blend-mode: darken; }

        &--nlmk {
            width: 27%;
            mix-blend-mode: darken; } }

    &__description {
        max-width: 145px;
        text-align: center;
        margin: 6% auto 18%;
        padding: 0 7px; }

    .swiper-button-next {
        left: 60px; }

    .slider-icon {
        font-size: 18px; }

    .swiper-slide {
        background: #F0F0F0;
        justify-content: center;
        display: flex;
        align-items: center; }

    .swiper-slide-active {
        transform: scale(1.8);
        z-index: 3;
        background: #F0F0F0;
        border-radius: 5px;
        transition: transform 0.3s ease; }

    .swiper-slide-next {
        transform: scale(1.4);
        z-index: 2;
        background: #E9E9E9;
        border-radius: 5px;
        transition: transform 0.3s ease; }

    .swiper-slide-prev {
        transform: scale(1.4);
        z-index: 2;
        background: #E9E9E9;
        border-radius: 5px;
        transition: transform 0.3s ease; }

    .swiper-container {
        padding-top: 60px;
        padding-bottom: 60px;
        overflow: hidden; } }

@media  (max-width: 1500px) {
    .text--card {
        font-size: 7px;
        line-height: 9px; }
    .slider-cards__navigation {
        padding: 100px 0 0 18px; } }

@media  (max-width: 1279px) {

    .slider-cards__body {
        display: block; } }

@media  (max-width: 750px) {

    .slider-cards__container {
        padding: 80px 10px 50px; } }

@media  (max-width: 480px) {
    .slider-cards {
        padding: 0; } }
