.characteristic-setion {
    margin-top: 124px; }

.characteristics-block {
    max-width: 621px;
    width: 100%;
    margin-bottom: 100px;

    &__title-text {
        margin-bottom: 40px;
        width: 957px;
 }        // width: 100%

    &__text {
        padding: 10px 0px 10px 0px;
        border-top: 1px solid #000;
        display: flex;
        justify-content: space-between; }

    &--border {
        border-top: none; }

    &--padding {
        padding-right: 370px; }

    &--padding-text {
        padding-right: 270px; }

    &--margin {
        padding-left: 34px; } }

.specifications {
    max-width: 621px;
    width: 100%;
    margin-bottom: 100px;

    &--indent {
        margin-left: 138px; }

    &__text {
        padding: 10px 0px 10px 0px;
        border-top: 1px solid #000;
        display: flex;
        justify-content: space-between;

        &--border {
            border-top: none; }

        &--padding {
            padding-right: 370px; }

        &--padding-text {
            padding-right: 270px; }

        &--margin {
            padding-left: 34px; } }

    &__title {
        max-width: 700px;
        width: 100%;

        &--indent {
            margin: 124px 0 62px 138px;
            max-width: 730px;
            width: 100%; } } }

@media  (max-width: 1279px) {

    // .specifications
    //     max-width: 450px
    //     padding: 0 20px 0 20px

    .characteristics-block {
        max-width: 450px;
        padding: 0 20px 0 20px; } }

@media  (max-width: 955px) {

    // .specifications
    //     max-width: 660px
    //     padding: 0 20px 0 20px

    .characteristics-block {
        max-width: 660px;
        padding: 0 20px 0 20px; } }

@media  (max-width: 860px) {
    .specifications--indent {
        margin-left: 20px;
        padding-right: 28px; }

    .specifications__title--indent {
        margin: 124px 0 62px 16px; }

    .specifications__title--indent {
        margin: 200px 0 62px 16px; } }
