.accordion {

    &__content {
        text-align: left;
        border-bottom: 1px solid #000; }

    &__body-button {
        display: flex;
        color: #B33525;
        align-items: center;
        gap: 9px;
        padding: 10px 0;
        &--hide {
            display: none; } }

    &__item {
        @include reset-btn();
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $white;
        padding: 0; }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 18px 0 18px;
        transition: background-color .3s ease;

        &--border {
            border-bottom: 1px solid #000; }

        &-img {
            display: none;
            color: #B33525;
            align-items: center;
            gap: 9px;
            padding-top: 10px;

            &--hide {
                display: flex; } }

        &--border {
            border-bottom: 1px solid #000; } }

    &__header-name {
        font-size: 20px;
        font-weight: 600;
        color: $black; }

    &__header-icon {
        @include flex-center();
        width: 26px;
        height: 26px;
        background-color: rgba(#92ABBC, .5);
        border-radius: 50%;
        transition: background-color .3s ease;

        .icon {
            color: $white;
            font-size: 12px;
            transition: transform .3s ease; }

        &--active {
            background-color: #92ABBC;

            .icon {
                transform: rotate(45deg); } } } }
