.summary {
    display: flex; }

.vacancies {

    &__title {
        text-align: center;
        margin: 36px 0 60px 0; }

    &__section {
        display: flex;
        justify-content: space-around;
        margin: 0 20px;
        gap: 93px;
        margin-bottom: 112px; }

    &__info {
        max-width: 877px;
        width: 100%; }

    &__image {
        max-width: 648px;
        width: 100%;
        height: 383px;
        object-fit: cover; } }

.conditions {

    &__button {
        @include reset-btn;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 19px 0;

        &--border {
            border-bottom: 2px solid #000; } }

    &__main {
        transition: 0.3s easy;
        border-bottom: 2px solid #000;

        &--visible {
            display: none; } }

    &__salary {
        margin: 40px 0 19px; }

    &__section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 531px;
        width: 100%;
        margin-bottom: 23px; }

    &__info {
        max-width: 531px;
        width: 100%; }

    &__block {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 5px 37px 5px 0;

        &--right {
            padding-left: 99px; } }

    &__info {
        margin-bottom: 23px; }

    &__button-response {
        margin-bottom: 49px; } }

.condition-button {

    &__section {
        display: flex;
        color: #B33525;
        align-items: center;
        gap: 9px; }

    &__image {
        font-size: 17px;
        transition: .4s;
        transform: rotate(180deg);

        &--rotate {
            transform: rotate(0deg); } } }

.custom-select__label {

    &--vacancies {
        padding: 26px 0 20px 28px;
        background: #FFFFFF; }

    &:hover {
        background: #F2DF75; } }

.custom-select__header {
    border: 1px solid #000;

    &:hover {
        border: 1px solid #F2DF75; } }

.custom-select:checked {
    background: #F2DF75; }

.custom-select__header {
    padding: 23px 0 23px 17px; }

.img-form {

    &--job {
        bottom: -36px; } }

@media  (max-width: 1279px) {
    .vacancies__section {
        gap: 70px; }

    .vacancies__image {
        height: 357px; } }

@media  (max-width: 1023px) {

    .vacancies__section {
        display: flex;
        flex-direction: column; }

    .vacancies__image {
        display: none; }

    .conditions__button-response {
        margin-bottom: 30px; } }

@media  (max-width: 767px) {
    .accordion__header {
        display: block; } }
