
.callback-form {
    background: #F8F8F8;
    border-radius: 5px;
    display: flex;
    gap: 250px;
    padding: 0 20px 0 20px;

    &__column {
        position: relative;

        &:nth-child(1) {
            margin-top: 90px;
            margin-left: 60px; }

        &:nth-child(2) {
 } }            // Позиционирование второй колонки

    .callback-form__title {
        font-size: 40px;
        line-height: 48px;
        max-width: 450px;
        display: flex;
        flex-direction: column; }

    .callback-form__text {
        font-family: $ff-d; } }

.callback-form__inputs {
    max-width: 500px;
    width: 100%;
    margin: 90px 0 86px;

    &--modal {
        margin: 20px 0 0 0; } }

.button-form {
    display: flex;
    // margin-bottom: 86px

    &__right {
        font-size: 10px;
        line-height: 12px;
        max-width: 244px;
        width: 100%;
        margin-left: 32px;
        padding-top: 17px; } }

.img-form {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -100px;
    right: -300px;

    &--index {
        top: 520px;
        right: -400px; } }

.input-file {
    position: relative;

    .file-icon {
        position: absolute;
        right: 21px;
        top: 21px; } }

.adress-inform {
    display: flex;
    justify-content: space-around;
    padding: 31px 20px 0px 30px;
    gap: 17px; }

@media  (max-width: 1300px) {
    .callback-form {
        display: flex;
        justify-content: space-between;
        gap: 25px; } }

@media  (max-width: 1024px) {
    .callback-form {
        gap: 25px; }

    .callback-form__column:nth-child(1) {
        margin-left: 30px; } }

@media  (max-width: 820px) {
    .callback-form {
        display: block; }

    .callback-form {
        padding: 40px 20px 1px 20px; }

    .callback-form__column:nth-child(1) {
        margin-top: 0px;
        margin-left: 0px; } }

@media screen and (max-width: 320px) {

    .callback-form__inputs {
        margin: 50px 0 50px; } }
