body {
	margin: 0;
	padding: 0;
	font-family: $ff;
	font-size: 16px;
	line-height: 1.3;
	overflow-x: hidden; }

* {
	box-sizing: border-box;
	font-family: $ff; }

.body {
	display: flex;
	margin-bottom: 20px;

	&--fix {
		position: fixed;
		left: 0;
		right: 0; }

	&--active {
		overflow: hidden; } }

a {
	text-decoration: none;
	color: #000000; }

input {
	outline: none; }

textarea {
	outline: none; }

::-webkit-scrollbar {
	width: 0; }
