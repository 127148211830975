
.container {
  max-width: 1785px;
  width: 100%;
  margin: 0 auto; }

.page-wrapper {
  display: flex;
  width: 100%;
  overflow-x: hidden;

  &__section {
    width: calc(100% - 56px); }

  &__aside {
    width: 56px;
    right: 0;
    position: fixed;
    height: 100vh;
    z-index: 1;
    background: #B33525;
    color: #FFFFFF;
    text-transform: uppercase;
    z-index: 9;

    .language-section {
      margin-top: 810px;
      display: flex;
      justify-content: space-evenly;
      align-items: center; } } }

.tabindex {
  position: relative;
  z-index: 1; }

.container_2 {
  max-width: 1379px;
  width: 100%;
  margin: 0 auto; }
