.feedback {

    &__item {

        &-text {
            font-weight: bold; } } }



.feedback__item-text {
	min-width: 100px; }
