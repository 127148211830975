@mixin reset-btn() {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  box-shadow: none; }

@mixin reset-input() {
  box-shadow: none;
  outline: none; }

@mixin overlay($color) {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    margin: 0 0 0 (-50vw);
    background: $color; } }

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center; }

@mixin flex-around() {
  display: flex;
  align-items: center;
  justify-content: space-around; }

@mixin flex-between() {
  display: flex;
  align-items: center;
  justify-content: space-between; }

@mixin bg-image() {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

@mixin text-14() {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  font-family: $ff-d; }
