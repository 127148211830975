.slider-years {
    margin-bottom: 100px;

    &__wrapper {
        max-width: 1379px;
        width: 100%;
        margin: 0 auto; }

    .swiper-container {
        max-width: 576px;
        width: 100%;
        margin-left: 10px;
        overflow: hidden; }

    .swiper-container1 {
        width: 576px; }

    &__slide {
        margin-top: 32px; }

    &__buttons {
        margin-left: 10px; }

    .swiper-slide {
        width: 576px; }

    &__title {
        margin-bottom: 42px; }

    &__btn {
        padding: 20px;
        background: #F2DF75;
        border-radius: 3px;
        border: none;
        transition: 0.5s; }

    &__years {
        display: flex;
        justify-content: space-between; }

    .border {
        background: #000;
        height: 1px;
        background: #000;
        height: 1px;
        position: relative;
        top: -53px;
        left: 64px;
        width: 480px; } }

.slider-years__section-year {
    overflow: hidden;
    width: 1200px;
    margin-left: 10px;

    .swiper-slide-next {

        .swiper-slide {
            width: 280px; } }

    .swiper-container {
        width: 1200px;
        overflow: hidden; }

    .swiper-slide {
        width: 576px; } }
