@mixin fontFace($fontName) {
  @font-face {
    font-family: quote($fontName);
    src: url('../fonts/' + $fontName + '/' + $fontName + '.eot');
    src: url('../fonts/' + $fontName + '/' + $fontName + '.eot?#iefix') format('embedded-opentype'), url('../fonts/' + $fontName + '/' + '.woff') format('woff'), url('../fonts/' + $fontName + '/' + $fontName + '.ttf') format('truetype'), url('../fonts/' + $fontName + '/' + $fontName + '.svg##{$fontName}') format('svg');
    font-weight: normal;
    font-style: normal; } }

@mixin fontFaceTtf($fontName) {
  @font-face {
    font-family: quote($fontName);
    src: url('../fonts/' + $fontName + '/' + $fontName + '.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback; }

  // &--margin
 }  //   margin-top: 0px

.big-text {
  font-size: 40px;
  line-height: 48px; }

.span-map {
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: #B33525;
    text-decoration: none; } }

@include fontFaceTtf('Golostextblack');
@include fontFaceTtf('Golostextdemibold');
@include fontFaceTtf('Golostextregular');
@include fontFaceTtf('golostext_bold');
