.top-picture {

	&__crumbs {
		display: flex;
		justify-content: left;
		margin-top: 25px;
		margin-left: 115px;

		&--wide {
			max-width: 558px; } }

	&__img {
		max-width: 1784px;
		width: 100%;
		height: 250px;
		object-fit: cover; } }

@media  (max-width: 1900px) {

	.top-picture__img {
		padding: 0 20px 0 20px; } }

@media (max-width: 320px) {
	.top-picture__img {
		padding: 0; } }


