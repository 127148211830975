.tab-section {
    margin-top: 100px;
    margin-bottom: 155px; }

.tabs {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-header {
        display: flex;
        align-items: center;
        white-space: nowrap;
        background: #F8F8F8;
        padding-left: 150px;

        &:empty {
            display: none; }

        &__button {
            @include reset-btn();
            color: #959191;
            padding: 93px 0px 25px 0px;
            margin-right: 73px;
            font-family: $ff;
            transition: background-color .2s ease;
            border-bottom: 7px solid transparent;

            &:last-child {
                margin-right: 0; }

            &--active {
                color: #B33525;
                border-bottom: 7px solid #B33525; } } }
    &-content {
        position: relative;
        overflow: hidden;

        &__tab {
            display: none;
            overflow-y: auto;
            overflow-x: hidden;

            &--active {
                height: auto;
                width: 100%;
                display: block; } } } }

.tabs-header__button {

    &:hover {
        color: #B33525; } }

@media  (max-width: 1279px) {
    .tabs-header {
        padding: 0 80px;
        margin: 0 auto; } }

@media  (max-width: 1024px) {
    .tabs-header {
        padding: 0 30px;
        margin: 0 auto; } }

@media  (max-width: 1023px) {
    .tabs-header {
        padding: 0;
        margin: 0 auto; } }

@media  (max-width: 950px) {
    .tabs-header__button {
        margin-right: 18px; } }

@media  (max-width: 600px) {
    .slider-tab__buttons {
        display: flex;
        flex-direction: column;
        align-items: baseline; }

    .slider-tab__title {
        margin-bottom: 20px; } }

@media  (max-width: 320px) {
    .slider-tab__schemes {
        margin-left: 16px; } }
