.map-wrapper {
	@include max-screen(930px) {
		overflow-x: auto;
		overflow-y: hidden; } }


.section-maps {
	position: relative;
	display: flex;
	height: 600px;
	width: max-content;
	margin: auto;

	&__points {
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;

		.country {
			position: absolute;

			&[data-code='EST'] {
				top: 33%;
				left: 12%; }

			&[data-code='LT'] {
				top: 38%;
				left: 11%; }

			&[data-code='LA'] {
				top: 41%;
				left: 12%; }

			&[data-code='BY'] {
				top: 46%;
				left: 13%; }

			&[data-code='RU'] {
				top: 28%;
				left: 50%; }

			&[data-code='KZH'] {
				top: 63%;
				left: 33%; }

			&[data-code='UZ'] {
				top: 76%;
				left: 31%; } }

		.city {
			position: absolute;

			&[data-code="RU-MSK"] {
				top: 42%;
				left: 23%; }

			&[data-code="RU-SPB"] {
				top: 30%;
				left: 20%; }

			&[data-code="VL"] {
				top: 44%;
				left: 13%; }

			&[data-code="RU-MUR"] {
				top: 10%;
				left: 23%; }

			&[data-code="RU-KRM"] {
				top: 63%;
				left: 20%; } } }

	&__svg {
		zoom: 145%;
		transform: translate(0, -145px) rotate(14deg); }

	path {
		fill: #FFFFFF;
		stroke: #CDCDCD;
		cursor: pointer;

		&.select-country {
			fill: url(#pattern-dash);

			&.active {
				fill: #EB7161 !important;
				stroke: #363230;
				stroke-width: 1;
				box-shadow: 0px 13px 16px rgba(0, 0, 0, 0.37); }

			&[data-code="LT"],
			&[data-code='KZ'] {
				fill: url(#pattern-circle); }

			&[data-code="LA"],
			&[data-code='UZ'] {
				fill: url(#pattern-dash-reverse); }

			&[data-code="BY"],
			&[data-code="EST"] {
				fill: url(#pattern-dash-ver); } } }

	&--home {
		height: 645px;
		overflow: hidden;

		.section-maps {

			&__svg {
				zoom: 160%;
				transform: translate(0, -145px) rotate(3deg); }

			&__points {

				.country {

					&[data-code='EST'] {
						top: 340px;
						left: 220px; }

					&[data-code='LT'] {
						top: 365px;
						left: 210px; }

					&[data-code='LA'] {
						top: 387px;
						left: 230px; }

					&[data-code='BY'] {
						top: 420px;
						left: 220px; }

					&[data-code='KZH'] {
						top: 480px; }

					&[data-code='UZ'] {
						top: 545px;
						left: 602px; } }

				.city {

					&[data-code="RU-MSK"] {
						top: 370px;
						left: 410px; }

					&[data-code="RU-SPB"] {
						top: 34%;
						left: 20%; }

					&[data-code="VL"] {
						top: 405px;
						left: 245px; }

					&[data-code="RU-MUR"] {
						top: 170px;
						left: 370px; }

					&[data-code="RU-KRM"] {
						top: 515px;
						left: 390px; } } } }

		@include min-screen(1279px) {
			position: absolute;
			height: 900px; } } }



.country {
	display: flex;
	align-items: center;
	grid-gap: 5px;

	&__text {
		font-size: 16px;
		line-height: 16px;
		text-transform: uppercase;
		color: rgba(0, 0, 0, 0.5); } }

.city {

	&__text {
		font-family: $ff-d;
		font-size: 9px;
		line-height: 100%;
		text-transform: uppercase;
		color: #000;
		padding: 1px;
		background: #FFF; } }


.point {
	width: 10px;
	height: 10px;
	background: #000;
	border-radius: 10px; }


.map-description {
	max-width: 1055px;
	width: 100%;
	margin: 0 auto;
	background: #FFF;
	position: relative;
	z-index: 1;
	padding-top: 50px;

	@include max-screen(1279px) {
		padding: 0 20px; } }



.baloon-title {
	width: 100%;
	max-width: 210px;
	font-family: $ff-d;
	font-size: 14px;
	line-height: 16px; }
