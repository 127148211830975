.production-section {
	margin-top: 25px;

	&__title {
		text-align: center; }

	&__main {
		display: flex;
		justify-content: space-evenly;
		margin-top: 98px;
		padding: 0 20px 0 20px;
		margin-bottom: 190px; }

	&__info {
		max-width: 610px;
		width: 100%; }

	&__text {
		margin-bottom: 20px; }

	&__img {
		max-width: 748px;
		width: 100%; }

	&__buttons {
		gap: 15px;
		display: flex;
		margin-top: 28px; }

	&__download {
		display: flex;
		gap: 10px; }

	&__download-img {
		font-size: 19px; } }

@media  (max-width: 1900px) {

	.production-section {

		&__main {
			gap: 52px; } } }

@media  (max-width: 1280px) {
	.production-section__img {
		max-width: 748px;
		width: 100%;
		height: 350px;
		object-fit: cover;
		border-radius: 5px; } }

@media  (max-width: 1024px) {

	.production-section__img {
		max-width: 748px;
		width: 100%;
		height: 350px;
		object-fit: cover;
		border-radius: 5px; } }

@media  (max-width: 1023px) {
	.production-section__main {
		flex-direction: column;
		align-items: center; } }

@media  (max-width: 767px) {

	.production-section__buttons {
		display: flex;
		flex-direction: column; }

	.production-section__buttons {
		.btn--yellow {
			width: 100%; } }

	.production-section__download {
		display: flex;
		justify-content: center; } }

@media  (max-width: 320px) {
	.production-section__main {
		padding: 0; }

	.production-section__info {
		padding: 0 16px; } }
