// Override Magnific Popup Styles
.mfp-close {
	display: none !important; }

.mfp-bg {
	background: rgba(39, 62, 92, 0.45); }

// Layout modals style
.modals {
	display: none; }

.modal {
	$el: &;

	background-color: $white;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	border-radius: 5px;
	position: relative;
	color: #000000;
	box-shadow: 0 5px 15px -10px rgba(34, 60, 80, 0.7);

	&--animate {
		animation-duration: .3s;
		animation-fill-mode: both; }

	&--error {

		#{$el} {

			&__title {
				color: $danger; }

			&__description {
				color: $danger; }

			&__button-close-icon {
				color: $danger; } } }

	&__inner {
		position: relative;
		padding: 30px;
		background: #F8F8F8;
		border-radius: 5px;

		.callback-form__column:nth-child(1) {
			margin-top: 0px; } }

	&__button-close {
		@include reset-btn;
		position: absolute;
		right: -20px;
		top: -20px;
		padding: 0;
		width: 20px;
		height: 20px;

		&-icon {
			font-size: 14px;
			color: $black; } }

	&__title {
		text-align: center; }

	&__body {
		margin-top: 20px; }

	&__description {
		text-align: center; } }



.scheme-modal {
	max-width: 1200px;

	&__img {
		width: 100%;
		object-fit: contain; }

	&__btn {
		display: block;
		margin: 30px auto 0; } }
