.aside {
    z-index: 5;

    &__icon-line {
        margin-top: 4px;
        font-size: 4px;

        &--active {
            display: none; }

        &--black {
            color: #000000; } }

    &__icon-close {
        width: 20px;
        height: 20px;
        display: none;

        &--active {
            display: block; } }

    &__language {
        display: none;
        margin-top: 761px;
        // display: flex
        align-items: center;
        justify-content: space-evenly;

        &--black {
            color: #000000; } }

    &__button {
        @include reset-btn;
        background: #B33525;
        padding-bottom: 40px;
        padding-top: 10px;

        &--yellow {
            background: #F2DF75; } }

    &__button-icon {
        background: #B33525;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--yellow {
            background: #F2DF75; } } }

.aisde__button-text {
    transform: rotate(90deg);
    color: #FFFFFF;
    margin-top: 23px;

    &--black {
        color: #000000; } }

.languale__icon {
    font-size: 16px; }

.page-wrapper__aside--yellow {
    background: #F2DF75;
    z-index: 9;
    height: 100px; }

.info-main {
    background-color: red;

    &__text {
        font-size: 12;

        &--bold {
            font-weight: bold; } } }
