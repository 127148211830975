.home {
	color: green; }

.top-section {
	position: relative;

	&__info {
		display: flex;
		justify-content: space-between;
		gap: 67px; }

	&__buttons {
		max-width: 556px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between; }

	&__video {
		position: relative;
		max-width: 1161px;
		min-width: 660px;
		width: 100%; }

	&__button {
		padding: 42px 0px 42px 30px;
		border: 1px solid #DACA72;
		border-radius: 5px;
		font-size: 35px;
		line-height: 42px;
		font-family: $ff-bb;
		background: #FFFFFF;
		text-align: start;
		transition: .3s;

		&:hover {
			background: #F2DF75;
			box-shadow: 0px 30px 18px rgba(0, 0, 0, 0.07); } }

	&__btn {
		position: absolute;
		right: 30px;
		bottom: 20px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 17px 20px 17px 20px;
		gap: 13px;
		width: 210px; } }

.video-main {

	&__video {
		position: relative;
		width: 100%;
		height: 438px;
		object-fit: cover;
		border-radius: 5px;

		&--wider {
			height: 552px; } } }

.production {

	&__title {
		text-align: center;
		margin: 100px auto;
		font-size: 40px;
		line-height: 48px;
		font-weight: bold;
		color: #000000;
		max-width: 750px; }

	&__cards {
		display: flex;
		justify-content: space-between;
		margin-top: 80px; }

	&__card {
		display: flex;
		align-items: flex-start;
		z-index: 1;
		max-width: 576px;
		width: 100%;
		background: #F2DF75;
		border-radius: 20px;
		padding: 100px 0px 98px 100px;
		display: flex;
		gap: 40px;
		box-shadow: 0px 30px 18px rgba(0, 0, 0, 0.07); }

	&__img {
		font-size: 43px; } }

.main-map {
	width: 100%;
	height: 1051px;
	border: 1px solid #000;
	text-align: center;
	background: #8794b9; }

.home-logo {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	background: #F8F8F8;
	padding: 60px 0px 60px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&__wrap {
		display: inline-flex;
		gap: 100px;
		padding-left: 100%;
		animation: marquee 35s infinite linear; }

	&__item {
		font-size: 100px;
		color: #000;
		color: #F8F8F8;
		text-transform: uppercase;
		text-shadow: 0 0 3px #B33525;
		font-weight: bolder;
		font-family: $ff-d; } }

@keyframes marquee {
	0% {
		transform: translate(0, 0); }
	100% {
		transform: translate(-100%, 0); } }

@media  (max-width: 1850px) {
	.top-section__buttons {
		margin-left: 20px; }

	.top-section__video {
		margin-right: 20px; }

	iframe {
		width: 800px; }

	.production__cards {
		margin: 80px 20px 0 20px;
		gap: 20px; } }

@media  (max-width: 1750px) {
	.production__card {
		padding: 100px 0 100px 30px;
		gap: 20px; }

	.top-info__card {
		padding-left: 30px;
		gap: 22px; } }

@media  (max-width: 1280px) {

	.catalog-servises__section {
		padding: 0 20px; }

	.top-section__video {
		min-width: 770px;
		width: 100%; }

	.top-section__button {
		font-size: 25px;
		line-height: 30px; }

	.top-section {
		gap: 44px; }

	.top-section__img {
		min-width: 616px; }

	.slider {
		gap: 10px; }

	.our-achievements__text {
		max-width: 212px;
		width: 100%;
		text-align: center; } }

@media  (max-width: 1024px) {

	.top-section__info {
		gap: 20px; }

	.production__card {
		padding: 80px 15px 80px 30px; }

	.slider-cards__body {
		display: block; }

	.our-achievements__img {
		left: 554px; }

	.catalog-servises__catalog {
		max-width: 400px;
		width: 100%; }

	.catalog-servises__section {
		gap: 30px; }

	.top-section__video {
		min-width: 616px; } }

@media  (max-width: 1023px) {
	.production__img {
		margin-bottom: 20px; }

	.top-section__info {
		display: block; }

	.top-section__buttons {
		gap: 15px;
		flex-direction: initial;
		max-width: none;
		margin-left: 0;
		padding: 0 20px 0 20px;
		margin-bottom: 20px; }

	.top-section__button--adapt {
		width: 40%; }

	.top-section__button--adapt-small {
		width: 30%; }

	.top-section__video {
		min-width: 672px;
		padding: 0 20px 0 20px; }

	.top-section__button {
		padding: 42px 0px 42px 0;
		text-align: center; }

	.production__card {
		display: block;
		padding: 45px 15px 45px 30px; } }

@media  (max-width: 767px) {
	.production__img {
		margin-bottom: 0; }

	.top-section__info {
		display: flex; }

	.top-section__buttons {
		order: 2; }

	.top-section__video {
		padding: 0; }

	.top-section__buttons {
		flex-direction: column; }

	.top-section__button--adapt {
		width: 100%; }

	.top-section__button--adapt-small {
		width: 100%; }

	.top-section__video {
		min-width: 320px; }

	.video-main__video {
		height: 250px; }

	.production__cards {
		display: block;
		margin: 40px 20px 0 20px; }

	.production__card {
		max-width: none;
		display: flex;
		justify-content: center;
		margin: 10px 0; }

	.production__title {
		text-align: center;
		margin-top: 40px;
		padding: 0 20px; }

	.catalog-servises__section {
		display: flex;
		flex-direction: column;
		align-items: center; }

	.catalog-servises {
		margin-bottom: 56px; } }

@media  (max-width: 460px) {
	.top-section__btn {
		position: absolute;
		right: 0px;
		bottom: 5px; } }

.home-map {
	display: flex;
	justify-content: space-between;
	margin-bottom: 100px;
	gap: 60px;

	&__left {
		padding-left: 30px;
		padding-top: 190px; }

	&__right {
		width: 70%; }

	@include max-screen(1279px) {
		flex-direction: column;
		gap: 0;

		&__left {
			padding: 100px 20px 0; }

		&__right {
			width: 100%; } }

	@include max-screen(1023px) {
		&__left {
			padding: 50px 20px 0; } } }

.list {

	&__item {
		display: flex;
		align-items: center;
		gap: 18px; }

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		min-width: 48px;
		height: 48px;
		background: #F2DF75;
		border-radius: 3px;

		.icon {
			font-size: 30px; } }

	&__text {
		width: 100%;
		padding: 20px 25px 20px 0;
		border-bottom: 1px solid #DACA72; }

	@include min-screen(1279px) {

		&__item {

			&:last-child {
				.list__text {
					border-bottom: none; } } } }

	@include screen(767px, 1279px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 50px; } }

.card-info {
	position: relative;
	width: 100%;
	max-width: 900px;
	background: #FFFFFF;
	border: 1px solid #000000;
	box-shadow: 0px 30px 18px rgba(0, 0, 0, 0.07);
	border-radius: 5px;
	padding: 30px 40px 40px 20px;
	margin: 695px 160px 0 auto;

	&__title {
		font-family: $ff-d;
		font-size: 25px;
		line-height: 30px;
		margin-bottom: 30px; }

	@include max-screen(1279px) {
		width: calc(100% - 40px);
		margin: 0 auto; } }
