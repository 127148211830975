.slider {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;

    &__buttons {
        display: flex;
        margin-top: 40px;
        gap: 10px; }

    &__container {
        width: 1161px;
        overflow: hidden; }

    &__btn {
        max-width: 377px;
        width: 100%; } }

.slider-servises {

    &__btn {
        padding: 20px;
        background: #F2DF75;
        border-radius: 3px;
        border: none;
        transition: 0.5s;

        &:hover {
            background: #FFEE93; } }

    // &__button
    //     position: absolute
    //     bottom: 50px

    &__img {
        width: 100%;
        margin-bottom: 25px;
        border-radius: 5px;
        height: 209px;
        object-fit: cover; }

    // &__slide
    //     position: relative

    &__describe {
        margin: 30px 0px 30px; }

    .slider-icon {
        font-size: 18px; }

    .swiper-wrapper {
        z-index: 0; }

    .swiper-slide {
        border: 1px solid #000;
        border-radius: 5px;
        padding: 45px 30px 50px;
        position: relative;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover .slide-img {
            box-shadow: 0px 30px 18px rgba(0, 0, 0, 0.15); } }

    .swiper-button-disabled {
        opacity: 0.5; } }

@media  (max-width: 1600px) {
    .slider {
        gap: 100px;
        margin: 0 20px 0 20px; } }

@media  (max-width: 1280px) {
    .slider__btn {
        width: 100%; }

    .slider {
        gap: 100px; } }

@media  (max-width: 840px) {
    .slider {
        flex-direction: column; }

    .slider__container {
        width: 100%; }

    .slider__btn {
        max-width: none; }

    .slider__buttons {
        margin-bottom: 40px; } }

@media  (max-width: 767px) {
    .slider {
        display: block; }

    // .our-achievements__section
    //     display: grid
 }    //     grid-template-columns: repeat(2, 1fr)

@media  (max-width: 320px) {
    .slider-servises .swiper-slide {
        padding: 10px 10px 50px; } }
