.animated {
	animation-duration: .3s;
	animation-fill-mode: both; }

.fade-in-up {
	animation-name: fadeInUp; }

.fade-out-up {
	animation-name: fadeOutUp; }

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 10px, 0); }

	100% {
		opacity: 1;
		transform: none; } }


@keyframes fadeOutUp {
	0% {
		opacity: 1; }

	100% {
		opacity: 0;
		transform: translate3d(0, -20px, 0); } }
