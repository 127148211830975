.header-section {

    &__logo {
        margin-left: 10px;
        width: 209px; }

    &__text {
        margin-left: 47px; }

    &__flex {
        display: flex;
        justify-content: space-between; }

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1150px;
        display: flex;
        gap: 34px;

        &__logo {
            margin-left: 10px; }

        &__menu {
            margin-right: auto;
            margin-left: 50px; }

        &__feedback {
            margin-right: 50px; } }
    &__menu {
        @include flex-center;
        padding-left: 0;
        list-style: none; }

    &__link {
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        &--cursor {
            cursor: pointer; } }

    &__item {
        position: relative;
        border-top: 6px solid transparent;
        margin-right: 20px;
        padding-top: 61px;
        padding-bottom: 37px;
        transition: .3s;

        &:hover {
            border-top: 6px solid #B33525;
            cursor: pointer;

            .menu__dropdown {
                display: flex; }

            .menu__link-icon {
                transform: rotate(180deg); } } } }

.menu-link {

    &__header {
        margin-right: 35px;
        padding-top: 34px;
        border-top: 6px solid transparent;
        padding-bottom: 40px;
        transition: .3s;

        &:hover {
            border-top: 6px solid #B33525; } }

    &__footer {
        margin-bottom: 35px; } }

.menu-container__feedback {
    display: flex;
    width: 660px;
    margin-left: 48px;
    align-items: baseline; }

.feedback {
    @include  flex-between();
    margin-right: 22px;
    gap: 32px;
    padding: 49px 20px 49px 0;

    &__item-icon {
        margin-right: 17px;
        font-size: 16px;

        &--large {
            margin-right: 33px; } }

    &__item {
        display: block;
 }        //width: 150px

    &__item-burger {
        display: block;
        width: 200px; } }

.container__menu {
    //width: 600px
    padding-right: 20px;
    flex-direction: column;
    align-items: none;
    justify-content: none;
    padding-left: 0px;
    list-style: none; }

.block {
    margin-bottom: 50px;
    margin-top: 22px;
    margin-left: 25px; }

.burger-links {

    &__link {
        text-decoration: underline;
        justify-content: space-between;
        font-family: $ff;
        text-transform: none;
        color: #000000;
        margin-top: 21px;
        display: flex;
        justify-content: flex-start;

        &:hover {
            color: #B33525; } } }

@media  (max-width: 1600px) {

    .feedback {
        display: block; }

    .feedback__item {
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        //justify-content: flex-start

        .btn--red {
            width: 200px; } }

    .feedback__button {
        padding: 10px 15px;
        width: 180px; }

    .feedback__button-burger {
        padding: 10px 15px;
        width: 180px; }

    .feedback__item-text {
        font-size: 14px;
        line-height: 17px; }

    .header-section__logo {
        width: 140px;
        margin-left: 20px; } }

@media  (max-width: 1260px) {
    .header-section__menu {
        display: none; } }

@media  (max-width: 1024px) {
    .feedback {
        display: flex;
        align-items: baseline;
        gap: 19px; } }

@media  (max-width: 1023px) {
    .menu-container__feedback {
        flex-direction: column;
        margin-left: 48px; } }

@media  (max-width: 786px) {
    .feedback__item {
        margin-bottom: 0px; }

    .top-section__info {
        flex-direction: column; }

    .header-section__block {
        padding-top: 2px; } }

@media  (min-width: 661px) {
    .container__menu {
        margin-left: 48px; } }

@media  (max-width: 600px) {
    .feedback__button {
        display: none; } }

@media  (max-width: 460px) {
    .feedback__button {
        display: none; }

    .header-section__logo {
        width: 100px; }

    .feedback__item-icon {
        display: none; }

    .feedback__item-text {
        margin-right: 30px; } }

header.header-section {
    position: relative;
    z-index: 2; }
