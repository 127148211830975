
.privacy-policy {

    &__title {
        text-align: center;
        margin: 36px 0 60px 0;

        &--bottom {
            margin-bottom: 120px; } }

    &__section {
        padding: 0 70px; }

    &__text {
        max-width: 1055px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px; } }

@media  (max-width: 1024px) {

    .privacy-policy__section {
        padding: 0 20px; } }
