.input-group {
	margin-bottom: 15px;

	&__error {
		color: #FC6464;
		font-size: 12px; }

	&--error {

		.input {
			background: #FFE1E1; }

		.input-group__error {
			padding-top: 10px; } } }

.input {
	&::-webkit-input-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; }

	&:-ms-input-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; }

	&::-moz-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; }

	&:-moz-placeholder {
		color: rgba(140, 140, 140, 0.5);
		transition: color ease .3s; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 32px #E7EAF4 inset !important;
	color: #8C8C8C; }

.input {
	border-radius: 3px;
	// background: #E7EAF4
	// color: #000000
	padding: 20px 15px;
	// min-height: 55px
	// border: none
	// box-shadow: none
	// outline: none
	// font-size: 14px
	width: 100%;
	// transition: background ease .3s
	background: #FFFFFF;
	border: 1px solid #000000;

	&--file {
		position: absolute;
		overflow: hidden;
		z-index: -1;
		width: 0.1px;
		height: 0.1px;
		opacity: 0; }

	&:disabled {
		background: #F8F8F8;
		border: 1px solid rgba(0, 0, 0, 0.5);
		cursor: not-allowed; }

	&--big {
		padding: 20px 0px 85px 15px; }

	&:hover:not(:disabled) {
		background: #FFFFFF;
		border: 1px solid #F2DF75; }

	&--completed {
		background: #FFFFFF;
		border: 1px solid #000000; }

	&--error {
		background: #FFFFFF;
		border: 1px solid #B33525; } }

.label {

	&--file {
		display: inline-block;
		cursor: pointer;
		width: 100%;
		padding: 23px 50px 23px 15px;
		background: rgba(255, 255, 255, 0.8);
		border: 1px solid #000000;
		margin-bottom: 28px;
		border-radius: 3px; } }

.checkbox {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	[type="checkbox"] {
		display: none;

		&:checked {

			~ {
				.checkbox__indicator {
					background: $primary;

					.icon {
						transform: scale(1); } } } }

		&:disabled {

			~ {
				.checkbox__indicator {
					cursor: not-allowed;
					background: #F6F7F9 !important;
					border: 1px solid rgba(0, 0, 0, 0.1);

					.checkbox__description {
						color: rgba(0, 0, 0, 0.6);
						cursor: not-allowed; } } } } }

	&__indicator {
		height: 19px;
		width: 19px;
		min-width: 19px;
		border: 1px solid $primary;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		overflow: hidden;
		transition: background ease .3s;

		.icon {
			transition: transform ease .3s;
			transform: scale(0);
			color: #ffffff !important;
			font-size: 11px;
			width: 100%;
			height: 100%;
			padding: 3px; } }

	&__description {
		font-size: 12px;
		color: #000000;
		padding: 0 14px;
		flex: 1;
		user-select: none; } }

.radio {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	[type="radio"] {
		display: none;

		&:checked {
			~ {
				.radio__indicator:after {
					top: 5px;
					bottom: 5px;
					left: 5px;
					right: 5px; } } }

		&:disabled {
			~ {
				.radio__indicator {
					cursor: not-allowed;
					border: 1px solid rgba(0, 0, 0, 0.1) !important;
					background: rgba(0, 0, 0, 0.1);

					&:after {
						background: #F6F7F9 !important; }

					.radio__description {
						color: rgba(0, 0, 0, 0.6);
						cursor: not-allowed; } } } } }

	&__indicator {
		background: $primary;
		height: 19px;
		width: 19px;
		min-width: 19px;
		border: 1px solid $primary;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		display: block;

		&:after {
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 50%;
			content: "";
			background: #ffffff;
			display: block;
			position: absolute;
			transition: top ease .1s, bottom ease .1s, left ease .1s, right ease .1s, } }

	&__description {
		font-size: 12px;
		color: #000000;
		padding: 0 14px;
		flex: 1;
		user-select: none; } }

.textarea {
	max-height: 130px;
	min-width: 100%;
	max-width: 100%;
	width: 100% !important; }

.form-default {

	&__row {
		display: flex;
		margin: 0 -8px; }

	&__column {
		width: calc(100% / 2 - 16px);
		margin: 0 8px; }

	.input-group {
		margin-bottom: 27px; }

	&__send {
		margin-top: 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		button {
			margin-right: 50px;

			span {
				white-space: nowrap; } } }

	&__consent {
		display: flex;
		align-items: center;

		a {
			color: #000000;

			&:hover {
				text-decoration: none; } } } }
