.our-achievements {
    margin-top: 200px;
    margin-bottom: 200px;

    &__section {
        position: relative;
        display: flex;
        justify-content: space-evenly; }

    &__block {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 288px;
        width: 100%; }

    &__text {
        margin-top: 20px; }

    &__span {
        color: #DACA72; }

    &__img {
        position: absolute;
        left: 304px;
        top: -340px; }

    &__numbers {
        display: flex;
        align-items: center; } }

.line-info__btn {
		display: flex;
		width: fit-content;
		margin-top: 15px; }

.catalog-servises {
    margin-bottom: 170px;

    &__section {
        display: flex;
        justify-content: center;
        gap: 106px; }

    &__catalog {
        max-width: 475px;
        width: 100%; }

    &__serveses {
        max-width: 475px;
        width: 100%; }

    &__link {
        display: flex;
        border-bottom: 1px solid #DACA72;
        padding: 25px 20px 25px 0;
        transition: .3s;
        align-items: center;
        color: #5A6872;

        &--black {
            color: #000; }

        &:hover {
            color: #B33525;
            padding-left: 10px; } }

    &__img {
        margin-right: 18px;
        text-align: center;
        font-size: 13px; } }

@media  (max-width: 1280px) {
    .line-info__btn {
        left: 51%; } }

@media  (max-width: 1024px) {
    .line-info__btn {
        left: 56%; } }

@media (max-width: 1023px) {
    .line-info__btn {
        left: 14%; } }

@media (max-width: 768px) {
    .line-info__btn {
        left: 18%; } }

@media (max-width: 767px) {
    .line-info__btn {
        left: 3%; } }

@media  (max-width: 767px) {
    .our-achievements__section {
        display: grid;
        grid-template-columns: repeat(2, 1fr); }

    .our-achievements__block {
        margin-bottom: 30px; }

    .our-achievements {
        margin-top: 50px;
        margin-bottom: 50px; } }
