.seo-title {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0; }

.typography {
	font-family: $ff;
	font-size: 14px; }

.title {
	font-family: $ff;
	font-size: 20px; }

.text {
	font-size: 16px;
	// text-transform: uppercase

	&--main {
		font-size: 15px;
		line-height: 22px;
		color: #000000;
		font-family: $ff; }

	&--main-bold {
		font-size: 15px;
		line-height: 22px;
		color: #000000;
		font-family: $ff-bb; }

	&--big {
		font-family: $ff-bb;
		font-size: 40px;
		line-height: 48px;
		font-weight: bold;
		color: #000000; }

	&--up {
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px; }

	&--products {
		font-family: $ff-bb;
		font-size: 35px;
		line-height: 42px; }

	&--crumbs {
		font-size: 13px;
		line-height: 16px;
		text-align: center;
		text-transform: uppercase;
		font-family: $ff;
		padding: 0px 20px;

		&:hover {
			color: #B33525; } }

	&--crumbs-red {
		font-size: 13px;
		line-height: 16px;
		text-align: center;
		text-transform: uppercase;
		font-family: $ff;
		color: #B33525;
		padding-left: 20px; }

	&--footer {
		font-size: 14px;
		line-height: 17px;
		text-decoration-line: underline;
		font-family: $ff;
		color: #000000; }

	&--grey {
		font-size: 15px;
		line-height: 18px;
		color: #8A8A8A;
		font-family: $ff; }

	&--achievements-title {
		font-style: normal;
		font-weight: 500;
		font-size: 60px;
		line-height: 72px; }

	&--achievements-text {
		font-size: 18px;
		line-height: 22px; }

	&--achievements-textBold {
		font-size: 18px;
		line-height: 22px;
		font-weight: bold; }

	&--description {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #000000;
		min-height: 50px; }

	&--description-red {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #B33525; }

	&--menu-header {
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;
		color: #000000;
		font-family: $ff-bb; }

	&--menu {
		font-size: 18px;
		line-height: 22px;
		text-transform: uppercase;
		color: #000000;
		font-family: $ff-bb;

		&:hover {
			color: #B33525; } }

	&--bold {
		font-family: $ff-bb;
		font-size: 16px;
		line-height: 19px; }

	&--info {
		font-size: 16px;
		line-height: 19px;
		color: #000; }

	&--info-grey {
		font-size: 16px;
		line-height: 19px;
		color: grey; }

	&--italic {
		font-style: italic; }

	&--not-found {
		font-size: 100px;
		line-height: 120px;
		color: #B33525;
		font-family: $ff-bb; }

	&--margin {
		margin-top: 20px; }

	&--card {
		font-size: 9px;
		line-height: 12px;
		color: #8A8A8A; } }

@media  (max-width: 1350px) {

	.text--menu-header {
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		color: #000000;
		font-family: $ff-bb; } }

@media  (max-width: 1280px) {

	.text--big {
		font-size: 30px;
		line-height: 36px; }

	.text--grey {
		font-size: 12px;
		line-height: 15px; }

	.text--products {
		font-family: $ff-bb;
		font-size: 25px;
		line-height: 30px; } }

@media  (max-width: 1024px) {
	.text--achievements-title {
		font-size: 40px;
		line-height: 48px; }

	.text--grey {
		font-size: 15px;
		line-height: 18px; } }

@media  (max-width: 320px) {
	.text--big {
		font-size: 25px;
		line-height: 30px; }

	.text--achievements-title {
		font-size: 30px;
		line-height: 36px; } }
