
// .crumbs
//     max-width: 235px
//     width: 100%
//     display: flex
//     justify-content: space-between
//     margin-top: 25px
//     margin-left: 115px

//     &--wide
//         max-width: 558px

.products {
    // margin-top: 25px

    &__section {
        margin-top: 25px; }

    &__title {
        display: flex;
        justify-content: center;
        text-align: center;
        max-width: 629px;
        width: 100%;
        margin: 0 auto; }

    &__blocks {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 100px 0 100px;
        align-items: center;
        gap: 60px;

        &--margin {
            margin: 73px 0 119px; } }

    &__block {
        max-width: 1216px;
        width: 100%;
        border: 1px solid #000000;
        border-radius: 5px;
        display: flex;
        justify-content: space-between; }

    &__block-title {
        margin-bottom: 8px;
        max-width: 454px;
        width: 100%; }

    &__btn {
        margin-top: 25px;

        &--margin {
            margin-top: 0px; } }

    &__info {
        padding: 80px 0px 0px 80px;
        max-width: 553px;
        width: 100%; }

    &__img {
        padding: 32px; }

    &__picture {
        max-width: 592px;
        width: 100%;
        height: 284px;
        object-fit: cover; } }

@media  (max-width: 1900px) {

    .products__blocks {
        padding: 0 20px 0 20px; } }

@media  (max-width: 768px) {
    .products__info {
        padding: 80px 0px 0px 30px; } }

@media  (max-width: 767px) {
    .products__info {
        padding: 80px 0px 0px 30px; }

    .products__block {
        display: block; }

    .products__info {
        padding: 22px 0px 0px 16px; }

    .products__picture {
        height: 150px; }

    .products__img {
        padding: 20px; } }
