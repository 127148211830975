.options-section {
    margin-top: 100px;

    &__info {
        padding: 10px;
        background: #F2DF75;
        border-radius: 5px;
        margin-bottom: 28px;
        width: 100%;

        &--margin {
            margin-top: 64px; } }

    &__title {
        max-width: 750px;
        width: 100%; }

    &__title-text {
        margin-bottom: 40px; }

    &__blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 80px; }

    &__block {
        max-width: 621px;
        width: 100%;
        margin-bottom: 100px; }

    &__text {
        padding: 10px 0px 10px 0px;
        border-top: 1px solid #000;
        display: flex;
        justify-content: space-between;

        &--border {
            border-top: none; }

        &--padding {
            padding-right: 370px; }

        &--padding-text {
            padding-right: 270px; }

        &--margin {
            padding-left: 34px; } } }

@media  (max-width: 1500px) {

    .options-section__title {
        padding-left: 20px; }

    .options-section__block {
        max-width: 570px;
        width: 100%;
        padding: 0 20px 0 20px; } }

@media  (max-width: 1279px) {

    .options-section__blocks {
        justify-content: space-around; }

    .options-section__block {
        max-width: 450px;
        padding: 0 20px 0 20px; } }

@media  (max-width: 1023px) {
    .options-section {
        margin-top: 200px; } }

@media  (max-width: 955px) {
    .options-section__block {
        max-width: 660px;
        padding: 0 20px 0 20px; }

    .options-section__block {
        margin-bottom: 50px; } }

@media  (max-width: 1279px) {
    .options-section__text--padding {
        padding-right: 0; }

    .options-section__text--padding-text {
        padding-right: 0; } }
