.standart-gost {

	&__title {
		text-align: center;
		margin-top: 36px;
		margin-bottom: 100px; }

	&__section {
		display: flex;
		justify-content: center;
		gap: 75px; }

	&__description {
		max-width: 877px;
		width: 100%; }

	&__images-block {
		position: relative; }

	&__image {
		max-width: 648px;
		height: 357px;
		width: 100%;
		object-fit: cover; }

	&__icon {
		position: absolute;
		right: 100px;
		top: 205px; } }

.description-table {
	margin-left: 41px;

	&__block {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #000;
		padding: 20px 0 20px; }

	&__text {
		max-width: 537px;
		width: 100%; }

	&__button {
		@include reset-btn;
		color: #B33525;
		display: flex;
		gap: 9px;
		align-items: center; }

	&__img {
		font-size: 18px; }

	&__title {
		width: 207px; } }

@media  (max-width: 1730px) {
	.standart-gost__images-block {
		padding-right: 40px; } }

@media  (max-width: 1279px) {

	.description-table__block {
		display: grid;
		grid-template-columns: 2.5fr 1fr; }

	.description-table__text {
		order: 3;
		grid-column: 1/4; }

	.description-table__button {
		justify-content: flex-end; }

	.description-table__text {
		max-width: 368px; }

	.standart-gost__section {
		gap: 49px; } }

@media  (max-width: 1023px) {
	.standart-gost__section {
		flex-direction: column; }

	.description-table {
		padding: 0 20px;
		margin: 0; }

	.standart-gost__image {
		max-width: none; }

	.standart-gost__images-block {
		padding: 0 20px; } }

@media  (max-width: 767px) {

	.description-table__button {
		justify-content: flex-start;
		order: 3; }

	.standart-gost__title {
		margin-bottom: 71px; }

	.description-table__block {
		gap: 10px; }

	.standart-gost__image {
		height: 250px; }

	.standart-gost__icon {
		display: none; }

	.standart-gost__images-block {
		padding: 0; } }
