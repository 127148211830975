
.map-info {
    padding: 0 20px 0 20px;

    @include max-screen(768px) {
        padding: 0px; }

    &__section {
        display: flex;
        justify-content: space-around;
        margin-bottom: 163px;
        gap: 91px;

        &--home {
            margin-top: 200px; } }

    &__button {
        display: flex;
        align-items: center; }

    &__info {
        margin-left: 67px;

        .info-main__section {
            align-items: center; } }

    &__main-block {
        max-width: 576px; }

    &__main-section {
        display: flex;
        justify-content: space-around;
        padding: 31px 20px 0px 30px;
        gap: 17px;

        .block2__button {
            display: flex;
            align-items: center; } }

    &__map {
        max-width: 1051px;
        width: 100%;
        height: 600px;
        border-radius: 5px;
        margin-top: 86px; } }

.info-adress {
    background: #F2DF75;
    box-shadow: 0px 30px 18px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    padding: 30px 0px 23px 30px;
    margin: 37px 0px 7px 0px;
    font-family: $ff;

    &__text {
        margin-top: 20px; } }

@media  (max-width: 1700px) {
    .map-info {
        &__map {
            max-width: 564px;
            width: 100%;
            height: 570px;
            border-radius: 5px;
            margin: 70px 0 0 0; } }

    .map-info__info {
        margin-left: 0px; }

    .map-info__section {
        gap: 11px; } }

@media  (max-width: 1280px) {
    // .map-info
    //     &__map
    //         max-width: 564px
    //         width: 100%
    //         height: 570px
    //         border-radius: 5px
    //         margin: 70px 20px 0 0

    .map-info__info {
        margin-left: 0px; }

    // .map-info__section
 }    //     gap: 11px

@media  (max-width: 1250px) {
    .map-info__section {
        display: block; }

    .map-info__map {
        max-width: 1200px;
        width: 100%;
        height: 416px; }

    .map-info__blocks-info {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; } }

@media  (max-width: 1250px) {

    // .map-info__section
    //     display: block

    .map-info__blocks-info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px; }

    .map-info__main-section {
        display: block; }

    .map-info__main-adress {
        width: 45%; }

    .map-info__blocks {
        width: 50%; }

    .btn--red {
        // width: 151px
        margin-top: 20px; } }

@media  (max-width: 1023px) {

    .map-info__section--home {
        margin-top: 100px; } }

@media  (max-width: 980px) {
    .map-info__blocks-info {
        display: block; }

    .map-info__main-section {
        display: flex;
        max-width: 576px; }

    .map-info__blocks {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; }

    .map-info__main-adress {
        width: 100%; }

    .map-info__main-adress {
        display: flex;
        justify-content: center; }

    .map-info__main-block {
        max-width: 672px;
        width: 100%; }

    .map-info__section {
        padding: 0 20px 0 20px; }

    .map-info__section--home {
        margin-bottom: 50px; } }

@media  (max-width: 500px) {

    .map-info__main-section {
        display: block; } }

@media  (max-width: 320px) {

    .map-info__main-section {
        padding: 31px 0px 0px 0px; }

    .info-adress {
        padding: 30px 0px 23px 14px; } }
