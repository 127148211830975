.footer {
    margin-top: 100px;

    &__link {
        display: block;
        margin-bottom: 24px;
        align-items: center;

        &--flex {
            display: flex; }

        &:hover {
            color: #B33525; } }

    &__section {
        background: #F8F8F8;
        padding: 67px 40px 30px 40px;
        border-radius: 5px; }

    &__hover {
        text-decoration: underline;
        justify-content: space-between;
        font-family: $ff;
        text-transform: none;
        color: #000000;
        margin-top: 21px;
        display: flex;
        justify-content: flex-start;
        max-width: 238px;
        width: 100%;

        &:hover {
            color: #B33525; } }

    &__hover-doc {
        text-decoration: underline;
        justify-content: space-between;
        font-family: $ff;
        text-transform: none;
        color: #000000;
        margin-top: 21px;
        display: flex;
        justify-content: flex-start;
        max-width: 238px;
        width: 100%;

        &:hover {
            color: #B33525; } }

    &__adapt-block {
        display: flex;
        gap: 80px; }

    &__block {
        text-align: left;

        // &--first-block
        //     margin-top: 20px
        &--list {
            margin-top: 20px; }

        &--align {
            text-align: end; }

        &--left {
            display: flex;
            gap: 20px; } }
    &__text {
        margin-top: 28px;
        max-width: 240px;
        width: 100%; }

    &__logo {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-end;
        margin-top: 27px; }

    &__icon {
        font-size: 16px;
        margin-left: 5px;
        transform: rotate(0);
        transition: transform 0.3s ease;

        &--bottom {
            font-size: 16px;
            margin-left: 5px;
            transform: rotate(0);
            transition: transform 0.3s ease; } }

    &__reqizit-icon {
        font-size: 16px;
        margin-left: 5px;
        transform: rotate(0);
        align-items: center;
        transition: transform 0.3s ease; }

    &__nav {
        display: flex;
        justify-content: space-between;
        border-radius: 5px; }

    &__links {
        display: flex;
        width: 100%;
        justify-content: space-between; }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__policy {
        display: flex;
        max-width: 464px;
        width: 100%;
        gap: 20px; } }

.footer-feedback {

    &__section {
        margin-top: 10px;
        text-align: flex-end; }

    &__main {
        display: flex;
        justify-content: space-between;
        gap: 32px;
        align-items: center;
        margin-bottom: 23px; }

    &__link {
        display: block; } }

@media  (max-width: 1520px) {

    .footer-feedback__link {
        margin-bottom: 20px;
        margin-top: 25px; }

    .footer__adapt-block {
        display: block;
        width: 182px; } }

@media  (max-width: 1280px) {

    .footer__links {
        grid-gap: 0px; }

    .footer-feedback__main {
        display: flex; }

    .footer {
        padding: 0 20px 20px 20px; }

    .text--menu {
        font-size: 14px;
        line-height: 27px; } }

@media  (max-width: 1230px) {
    .footer-feedback__main {
        display: block; }

    .footer-feedback__section {
        width: 180px; }

    .footer__links {
        grid-gap: 0px; } }

@media  (max-width: 1080px) {
    .footer__block--list {
        margin-top: 10px; }

    .footer-feedback__link {
        margin-top: 15px; }

    .footer__icon {
        width: 120px; }

    .footer__text {
        max-width: 180px; }

    .footer-feedback__main {
        display: block; } }

@media  (max-width: 960px) {
    .footer__icon {
        display: none; }

    .footer__hover--adapt {
        width: 84px; }

    .footer__links {
        grid-gap: 10px; }

    .footer__links {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr; }

    .footer__adapt-block {
        order: 2; }

    .footer__block--list {
        order: 1; }

    .footer__block--adapt {
        grid-column: 1 / 4;
        order: 4; }

    .footer-feedback {
        order: 3; }

    .footer__text {
        max-width: none; }

    .footer__section {
        padding: 67px 20px 30px 20px; }

    .footer__policy {
        gap: 0; } }

@media  (max-width: 720px) {
    .footer {
        padding: 0; }

    .footer__block {
        display: flex;
        flex-direction: column;
        align-items: center; }

    .footer-feedback__main {
        display: flex;
        flex-direction: column;
        gap: 0; }

    .footer-feedback__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 680px; }

    .footer__info {
        display: block; }

    .footer__policy {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 20px;
        max-width: 680px; }

    .footer__logo {
        justify-content: center; }

    .footer__nav {
        display: block; }

    .footer__block--list {
        order: 1; }

    .footer__links {
        flex-direction: column; }

    .footer__hover {
        display: none; }

    .footer__section {
        padding: 31px 16px 21px 16px; }

    .footer__adapt-block {
        width: 100%;
        gap: 0; }

    .footer__links {
        gap: 0;
        display: flex; }

    .footer__text {
        text-align: center; }

    .footer__feedback {
        border-top: 1px solid rgba(0, 0, 0, 0.2); }

    .footer-feedback__section {
        width: 180px;
        display: contents; } }

@media  (max-width: 460px) {
    .page-wrapper__aside {
        height: 0vh; }

    .page-wrapper__section {
        width: 100%; }

    .footer__policy {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px; }

    .footer__hover-doc {
        display: block;
        text-align: center; }

    .footer-feedback__info {
        text-align: center; }

    .footer__links {
        grid-gap: 0px; } }

@media  (max-width: 320px) {
    .footer {
        margin-top: 50px; }

    .aside__language {
        display: none; }

    .footer__links {
        grid-gap: 0;
        display: flex; }

    .footer__block {
        text-align: center; }

    .footer__block--list {
        margin-top: 0; }

    .footer__hover {
        display: none; }

    .footer__hover-doc {
        display: block;
        text-align: center; }

    .footer__info {
        display: block; }

    .footer__logo {
        justify-content: center; }

    .footer__reqizit-icon {
        display: none; }

    .footer-feedback__link {
        margin-top: 0px;
        padding-top: 15px;
        text-align: center; }

    .footer-feedback__main {
        display: flex;
        flex-direction: column;
        gap: 0; }

    .footer__feedback {
        border-top: 1px solid rgba(0, 0, 0, 0.2); }

    .footer-feedback__info {
        max-width: 291px;
        text-align: center; }

    .footer__link--flex {
        display: block;
        text-align: center; } }
