.bread-crumbs {
	max-width: 235px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	margin-left: 115px;

	&--wide {
		max-width: 490px; }

	&--job {
		margin-left: 70px; } }

.bread-crumb {
	color: #000000;
	font-size: 12px;
	text-decoration: none;
	font-family: $ff;
	line-height: 18px;
	white-space: nowrap;

	&:after {
		content: '-';
		padding-left: 6px;
		padding-right: 6px;
		text-decoration: none !important; }

	&:not(a) {
		color: #848E99;

		&:after {
			display: none; } }

	&:hover span {
		text-decoration: underline; } }

@media  (max-width: 1280px) {
	.bread-crumbs {
		margin-left: 20px; } }
