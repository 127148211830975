
.main-news {
	margin-top: 92px;

	&__section {
		position: relative;
		display: flex;
		justify-content: space-evenly; }

	&__actual {
		display: flex;
		margin-top: 15px; }

	&__text {
		max-width: 594px;
		margin-bottom: 15px; }

	&__block {
		background: #000;
		height: 1px;
		width: 55px;
		margin: 12px 11px 0 0; }

	&__image {
		position: absolute;
		left: 346px;
		top: 697px;
		z-index: 2; } }

.news-img {

	&__image {
		width: 100%;
		height: 552px;
		object-fit: cover;
		padding: 0 26px;
		border-radius: 5px; } }

@media  (max-width: 1280px) {
	.main-news__actual {
		display: none; } }

@media  (max-width: 1023px) {

	.main-news__section {
		display: flex;
		gap: 27px;
		flex-direction: column;
		margin: 0 20px; }

	.main-news__image {
		display: none; } }

@media  (max-width: 767px) {
	.news-img__image {
		height: 250px;
		padding: 0; } }
