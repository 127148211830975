.btn {
	@include reset-btn;

	padding: 20px 15px;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #000000;
	text-transform: uppercase;
	border-radius: 3px;
	transition: .3s;

	&--yellow {
		background-color: #F2DF75;
		font-family: $ff-bb;

		&:hover {
			background-color: #FFE553; }

		&:disabled {
			background-color: #E0E0E0;
			color: #000000; } }

	&--red {
		background-color: #B33525;
		color: #FFFFFF;
		width: 182px;

		&:hover {
			background-color: #D4331E;
			color: #FFFFFF; }

		&:disabled {
			background-color: #E0E0E0;
			color: #FFFFFF; } }

	&--white {
		background-color: #FFFFFF;
		border: 1px solid #F2DF75;

		&:hover {
			background-color: #F2DF75;
			color: #000000; }

		&:disabled {
			background-color: #FFFFFF;
			color: #000000; } } }

.button__up {
	background-image: url("./img/20.svg");
	display: block;
	&--img {
		width: 20px;
		height: 20px; } }

.btn-to-top {
	background: #000000;
	border: none;
	color: white;
	position: fixed;
	bottom: 20px;
	left: 20px;
	width: 49px;
	height: 49px;
	border-radius: 2px;
	z-index: 1;
	opacity: 0;
	transition: .3s;

	&__icon {
		background: #000000;
		color: white;
		font-size: 21px; } }
