.contacts-crumbs {

    .bread-crumbs {
        margin-left: 0; } }

.contacts-info {

    &__title {
        margin: 31px 0 81px; }

    &__adress {
        max-width: 576px;
        width: 100%; }

    &__people {
        max-width: 1200px;
        width: 100%; }

    &__section {
        display: flex;
        justify-content: center;
        gap: 84px;
        margin-bottom: 168px; }

    .info-adress {
        background: #F2DF75;
        box-shadow: 0px 30px 18px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        padding: 30px 0px 23px 30px;
        margin: 0px 0px 7px 0px;
        font-family: $ff; } }

.people-cards {

    &__title {
        margin-bottom: 52px; }

    &__section {
        display: grid;
        gap: 0 40px;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        padding: 0 20px;

        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr); }

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 1fr); }

        @media (max-width: 700px) {
            grid-template-columns: 1fr; } }

    &__card {
        max-width: 267px;
        width: 100%;

        @media (max-width: 700px) {
            max-width: none; } } }

.person-info {

    &__border {
        border: 1px solid #000;
        height: 1px;
        margin: 10px 0; }

    &__img {
        margin-bottom: 20px; }

    &__name {
        min-height: 42px; }

    &__position {
        margin-bottom: 15px;
        min-height: 40px; }

    &__email {
        margin: 15px 0 20px; }

    &__messengers {
        margin-top: 20px; } }

.messengers {
    max-width: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 63px;

    &__img {
        transition: .3s;

        &:hover {
            opacity: .5; } }

    &__telegram {
        transition: .3s;
        position: relative;

        &:hover::after {
            content: attr(data-title);
            position: absolute;
            top: 30px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 5px 10px; } }

    &__whatsapp {
        transition: .3s;
        position: relative;

        &:hover::after {
            content: attr(data-title);
            position: absolute;
            top: 30px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 5px 10px; } }
    &__skype {
        transition: .3s;
        position: relative;

        &:hover::after {
            content: attr(data-title);
            position: absolute;
            top: 30px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 5px 10px; } } }

.information {
    margin-bottom: 116px;

    &__section {
        display: flex;
        justify-content: space-between;
        padding: 0 20px; }

    .adress-inform {
        background: #F8F8F8;
        border-radius: 5px;

        &--bottom {
            padding-bottom: 39px; } }

    .map-info__map {
        margin-top: 0; } }

.requisites {
    margin-top: 138px;

    &__section {
        display: flex;
        gap: 375px; }

    &__title {
        margin-left: 60px; }

    &__info {
        max-width: 466px;
        width: 100%; }

    &__text {
        margin-bottom: 30px; } }

.section-text {

    &__text {
        max-width: 276px;
        width: 100%;
        border-bottom: 1px solid #000;
        padding: 9px 0 9px; } }

@media  (max-width: 1800px) {
    .contacts-info {
        padding: 0 20px; }

    .contacts-crumbs {
        padding-left: 22px; } }

@media  (max-width: 1280px) {
    .contacts-info__section {
        display: flex;
        flex-direction: column; } }

@media  (max-width: 1279px) {

    .information {

        &__adress {
            margin-bottom: 30px; } }

    .information .adress-inform {
        justify-content: flex-start; }

    .requisites__section {
        display: none; } }

@media  (max-width: 1250px) {
    .information__section {
        display: flex;
        flex-direction: column; } }

@media  (max-width: 1023px) {
    .contacts-info__section {
        margin-bottom: 100px; }

    .contacts-info .info-adress {
        padding: 30px 17px 23px 17px; } }

@media  (max-width: 767px) {

    .adress-inform {
        display: block; } }
